import { Box, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <Text>Page Not Found:</Text>
      <button onClick={() => navigate(-1)}>&larr; Go back</button>
    </Box>
  );
};

export default PageNotFound;
