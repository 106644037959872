import { ChakraProvider, theme } from '@chakra-ui/react';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Error from './pages/Error';
import Home from './pages/Home';
import PageNotFound from './pages/PageNotFound';

export const App = () => (
  <ChakraProvider theme={theme}>
    <Navbar />
    <Routes>
      <Route path='/' element={<Home />} errorElement={<Error />} />
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  </ChakraProvider>
);
