import { Box, Grid, Link, Text, VStack } from '@chakra-ui/react';
import { ColorModeSwitcher } from '../ColorModeSwitcher';

const Home = () => {
  return (
    <Box textAlign='center' fontSize='xl'>
      <Grid minH='100vh' p={3}>
        <VStack spacing={8}>
          <ColorModeSwitcher justifySelf='flex-end' />
          <Text maxW='510px'>
            Hello! My website is currently under construction, however, please
            feel free to check out my{' '}
            <Link
              color='teal.500'
              href='https://www.linkedin.com/in/meron-habtemariam/'
            >
              Linkedin profile
            </Link>
            !
          </Text>
          <Text>Chat soon!</Text>
        </VStack>
      </Grid>
    </Box>
  );
};

export default Home;
